// src/components/Header.js (or any appropriate component)
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import Filters from './Filters/Filters';
import Cards from './Cards/Cards';

function Wiki({defaultRoutes, privateRoutes}) {
  let [pageNumber, setPageNumber] = useState(1);
  let api = `https://rickandmortyapi.com/api/character/?page=${pageNumber}`;

  useEffect(()=>{


  },[api]);

  return (
    <div>   +
      <h1 className='text-center ubuntu my-4'>People <span className='text-primary'> Wiki</span></h1>
    <div className='container'>
      <div className="row">
        <div className="col-3">
        <Filters/>
        </div>
        <div className="col-8">
          <div className="row">
           <Cards/>
           <Cards/>
           <Cards/>
          </div>
        </div>
      </div>
      </div>
  </div>
  );
};

export default Wiki;