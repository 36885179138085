// src/components/AboutPage.js
import React from 'react';

function AboutPage() {
  return (
    <div>
      <h2>About Reed and his website</h2>
    </div>
  );
};

export default AboutPage;