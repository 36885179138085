import React from 'react';
import tableData from '../data/gains.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import '../css/table.css';

function Table() {

    const customStyles = {
        border: '0',       // CSS property: value
        backgroundColor: 'white'    // CSS property: valu
      };

    const goalEntry = tableData.progress.find(entry => entry.title === "Goal");

    let painGoal = null;
    let sleepGoal = null;
    let calorieGoal = null;
    let workoutGoal = null;
  
    if (goalEntry) {
      painGoal = goalEntry.inputs.pain;
      sleepGoal = goalEntry.inputs.sleep;
      calorieGoal = goalEntry.inputs.calories;
      workoutGoal = goalEntry.inputs.workouts;
    }
  return (
    <div className="table-container">
      <table>
        <thead>
        <tr>
      <th style={customStyles}></th>
      <th style={customStyles}></th>
      <th colspan="4" className='text-center'>Inputs</th>
      <th style={customStyles}></th>
      <th colspan="4"className='text-center'>Outputs</th>
      </tr>
          <tr>
            <th className='text-center'>Title</th>
            <th className='text-center'>Date</th>
            <th className='text-center'>Pain</th>
            <th className='text-center'>Sleep</th>
            <th className='text-center'>Calories</th>
            <th className='text-center'>Workouts</th>
            <th style={customStyles}></th>
            <th className='text-center'>Muscle Mass</th>
            <th className='text-center'>Body Fat</th>
            <th className='text-center'>Weight</th>
          </tr>
        </thead>
        <tbody>
          {tableData.progress.map((entry, index) => (
            <tr key={index}>
              <td>
                <a href={entry.link}>{entry.title}</a>
              </td>
              <td>{entry.date}</td>
              <td className={entry.inputs.pain < painGoal ? "red" : ""}>
                {entry.inputs.pain}
              </td>
              <td className={entry.inputs.sleep < sleepGoal ? "red" : ""}>
                {entry.inputs.sleep}
              </td>
              <td className={entry.inputs.calories < calorieGoal ? "red" : ""}>
                {entry.inputs.calories}
              </td>
              <td className={entry.inputs.workouts !== workoutGoal ? "red" : ""}>
                {entry.inputs.workouts}
              </td>
              <td></td>
              <td>{entry.outputs.muscleMass}</td>
              <td>{entry.outputs.bodyFat}</td>
              <td>{entry.outputs.weight}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;