import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutPage from "./AboutPage";
import Values from './Values';
import Socials from './Socials';
import Gains from './Gains';
import Projects from './Projects';
import '../css/header.css';
import Header from './Header';
import Wiki from './Wiki';


function App() {
  const defaultRoutes = [
    { path: '/about', element: <AboutPage /> },
    { path: '/projects', element: <Projects /> },
    { path: '/socials', element: <Socials /> },
  ];
  const privateRoutes = [
    { path: '/values', element: <Values /> },
    { path: '/wiki', element: <Wiki /> },
  ];
  const routes = [
    ...defaultRoutes, 
    ...privateRoutes,
    { path: '/projects/gains', element: <Gains /> },
  ];
  return (
    <Router>
      <Header defaultRoutes={defaultRoutes} privateRoutes={privateRoutes} />
        <Routes>
        {routes.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Router>
  );
}

export default App;