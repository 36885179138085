// src/components/Header.js (or any appropriate component)
import React from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from './NavigationBar';

function Header({defaultRoutes, privateRoutes}) {
  return (
    <header>
        <div className="header-text">
        <Link className="page-title" to='/' > 🏰 Reedtopia</Link>
        <NavigationBar defaultRoutes={defaultRoutes} privateRoutes={privateRoutes} />
        </div>
    </header>
  );
};

export default Header;