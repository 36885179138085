import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/header.css'

function NavigationBar({defaultRoutes, privateRoutes}) {
  const [menuOptions, setMenuOptions] = useState(defaultRoutes);
  const [buttonClicked, setButtonClicked] = useState(false); 
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [enteredCode, setEnteredCode] = useState('');
  const correctCode = 'a'; // Change this to the correct code

  const handleCodeInputChange = event => {
    setEnteredCode(event.target.value);
  };

  // in the future, thake the routes in as a prop from App
  const addNewOptions = () => {
    if (enteredCode === correctCode) {
      const newOptions = [
        { id: 4, label: 'Values', to: '/values' },
        { id: 5, label: 'Goals', to: '/goals' },
        { id: 5, label: 'Wiki', to: '/wiki' },
        // Add more new options as needed
      ];
      setMenuOptions([...menuOptions, ...privateRoutes]);
      setEnteredCode('');
      setShowCodeInput(false);
      setButtonClicked(true);
    } else {
      alert('Incorrect code. Please try again.');
    }
  };

  return (
    <nav className='navigation'>
      
        {menuOptions.map(route => (
          
            <Link className='navs' to={route.path}>{route.path.slice(1)}</Link>
          
        ))}
      
      {!buttonClicked && !showCodeInput && (
        <button onClick={() => setShowCodeInput(true)}>
          Login
        </button>
      )}
      {showCodeInput && (
        <div>
          <input
            type="password"
            placeholder="Enter code"
            value={enteredCode}
            onChange={handleCodeInputChange}
          />
          <button onClick={addNewOptions}>Submit</button>
        </div>
      )}
    </nav>
  );
}

export default NavigationBar;
