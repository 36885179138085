import React from 'react';
import {Link } from 'react-router-dom';
import values from '../data/values.json';

function Projects() {
  return (
    <div>
      {values.values.map(value => (
        <div key={value.title}>
          <ul>
          {value.projects.map(project => (
            project.access !== 'private' && (
            <Link className='font-weight-bold' to={'/projects/' + project.title} > {value.emoji} {project.title}</Link>
            )
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Projects;