// src/components/AboutPage.js
import React from 'react';

function Socials() {
  return (
    <div id="socials" class="socials">
    <p>Good Reeds</p>
    <p>Strava</p>
    <p>Linked In</p>
    <p>Mountain Project</p>
</div>
  );
};

export default Socials;