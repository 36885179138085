// src/components/AboutPage.js
import React from 'react';
import values from '../data/values.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';


function Values() {
  return (
    <div id="values" className='values mb-2'>
        {values.values.map((category, index) => (
        <div key={index}>
          <h2 className={category.color}>{category.emoji}{category.title}</h2>
          <ul>
            {category.practices.map((practice, practiceIndex) => (
              <li key={practiceIndex}>{practice.title}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
export default Values;