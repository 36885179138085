// src/components/AboutPage.js
import React from 'react';
import '../css/header.css'
import '../css/core.css'
import '../css/table.css'
import Table from './Table'

function Gains() {
  // Calculate the progress percentage (replace this with your calculation)
  const startWeight = 170.9;
  const currentWeight = 171.8; // Example: 50%
  const goalWeight = 180; // Example: 50%
  const calculatedPercentage = 100*(currentWeight-startWeight)/(goalWeight-startWeight); // Example: 50%
  const caclculatedWidth  = {
    width: calculatedPercentage + "%"       // CSS property: value
  };
  
  return (
<div>
<body>
<header>
   <div class="header-text">
     <div>
      <div class="page-title"> &#x1F3CB; Reed Gains Weight</div>
      <div class="rounded-rectangle">
        <div class="goal">⚖️ 180 lbs.</div>
        <div class="timeline strike-through">⌛ 8 weeks</div>
        <div class="timeline">⌛ 2023</div>
      </div>
     </div>
    <div class="navigation">
      <a class="navs" href="#progress-section">Strategy</a>   
      <a class="navs" href="#schedule" >Results</a>
      <a class="navs" href="#food" >Daily Raw Data</a>  
    </div>
    <div class="hamburger-menu">
      <div class="hamburger-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    </div>
    <nav class="mobile-nav">
      <ul>
        <li><a  href="#progress-section">Strategy</a></li>
        <li><a href="#schedule" >Results</a></li>
        <li><a href="#food" >Raw Data</a></li>
      </ul>
    </nav>
    </header>
    <div class="horizontal-content">
      <a class="deets" href="https://medium.com/@reed.klaeser/the-goal-351770508a1d">❓ Why/What</a>
      <a class="deets" href="https://medium.com/@reed.klaeser/measuring-progress-a5b75536f966">⚖️ Measuring progress</a>
      <a class="deets" href="https://medium.com/@reed.klaeser/workouts-2af8fc2512a3">📋 Workouts</a>
      <a class="deets" href="https://medium.com/@reed.klaeser/nutrition-1a54603b0394">🍌 Nutrition</a>
      <a class="deets" href="https://medium.com/@reed.klaeser/recovery-19785d98668b">🧘 Recovery</a>
  </div>
  <div id="progress-section" class="progress-section">
      <div class="progress-bar">
        <div class="progress" id="progress" style={caclculatedWidth}></div>
    </div>
  </div>
  <Table />
  <div id="schedule" class="schedule">
        <div class="schedule-title">
          <div id="title">DAILY RAW DATA</div>
          <div id="subtitle">Click "more details"<br /> to see what I'm lifting and eating.</div>
        </div>
        <iframe title='calendar' src="https://calendar.google.com/calendar/embed?src=luhq93354m76983ijvqehik430%40group.calendar.google.com&ctz=America%2FChicago" style={{border: "0"}} width="80%" height="350px" frameborder="0" scrolling="no"></iframe>         
      </div>
</body>
</div>
  );
};

export default Gains;